<template>
  <div>
    <v-col cols="3">
      <v-select
        :items="seller_list"
        v-model="selected_seller"
        item-text="username"
        item-value="id"
        label="Seller"
        placeholder="Seller's Name"
        color="#002441"
        @input="getIndividualSellerTransactions()"
      ></v-select>
    </v-col>
    <v-row class="mb-5 d-flex flex-row">
      <v-col cols="4">
        <v-card class="d-flex flex-column" style="background: #002441">
          <v-card-title style="font-size: 20px; color: white" class="pt-5 mb-2">
            Total Payable
            <v-spacer></v-spacer>
            <v-list text style="background: #002441; color: white">{{
              total_payable
            }}</v-list>
          </v-card-title>
        </v-card>
      </v-col>
      <v-specer></v-specer>
      <v-col cols="4">
        <v-card class="d-flex flex-column" style="background: #002441">
          <v-card-title style="font-size: 20px; color: white" class="pt-5 mb-2">
            Total Receiveable
            <v-spacer></v-spacer>
            <v-list text style="background: #002441; color: white">{{
              total_receivableable
            }}</v-list>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      :headers="transactions_headers"
      :items="mother_transactions"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-0"
    >
      <!-- <template v-slot:item.active_status="{ item }">
        <v-chip
          :color="
            item.status == 'Pending'
              ? 'warning'
              : item.status == 'Approved'
              ? 'green'
              : 'red'
          "
          dark
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <p
          class="mb-0 blue--text text-decoration-underline"
          style="cursor: pointer"
          @click="onViewReceipt(item)"
        >
          View Receipt
        </p>
      </template>
      <template v-slot:item.custom_date="{ item }">
        <p v-if="item.created_date">
          {{ new Date(item.created_date).toLocaleString() }}
        </p>
      </template>
      <template v-slot:item.approve_action="{ item }">
        <div class="d-flex flex-row">
          <v-btn
            class="mx-2"
            outlined
            small
            @click="approveTransaction(item)"
            color="green"
            v-if="item.status == 'Pending'"
          >
            Approve

          </v-btn>

          <v-btn
            class="mx-2"
            outlined
            small
            @click="deleteTransaction(item)"
            v-if="item.status == 'Pending'"
            color="red"
          >
            Cancel

          </v-btn>

          <v-btn text @click="onApprove(item)" v-else> Details </v-btn>
        </div>

      </template> -->
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      seller_list: [],
      selected_seller: "",
      total_payable:"",
      total_receivableable:"",
      transactions_headers: [
        {
          text: "Invoice ID",
          value: "invoice_id",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Order Date",
          align: "start",
          sortable: false,
          value: "ordered_date",
          class: "custom-header black--text",
          divider: true,
        },
        {
          text: "Total Price",
          value: "price_total",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },
        {
          text: "Payment Method",
          value: "payment_method",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },
        {
          text: "Payable",
          value: "total_admin_payable",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Recieveable",
          value: "total_admin_receiveable",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },

        // {
        //   text: "Merchant Payable",
        //   value: "total_seller_payable",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        // },
        // {
        //   text: "Deposited Currency",
        //   value: "iron",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        // },
        // {
        //   text: "Merchant Receiveable",
        //   value: "total_seller_receiveable",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        // },
        // {
        //   text: "Action",
        //   value: "approve_action",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        //   align: "center",
        // },
      ],
      mother_transactions: [],
    };
  },

  methods: {
getAllTransactions() {
      this.loading = true;
      axios
        .get(`Cart/admin_commission/`)
        .then((response) => {
          if (response.data.success && response.data.data) {
            this.mother_transactions = response.data.data;
          }else{
             this.mother_transactions = []
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
        axios
        .get(`Cart/total_admin_commission/`)
        .then((response) => {
          if (response.data.success) {
          this.total_payable=response.data.overall_payable;
          this.total_receivableable=response.data.overall_receiveable;
          }else{
             this.total_payable = 0;
             this.total_receivableable = 0;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getIndividualSellerTransactions() {
      axios
        .get(
          "Cart/individual_merchant_commission/" + this.selected_seller + "/"
        )
        .then((mother_transactions_response) => {
          if (
            mother_transactions_response.data.success &&
            mother_transactions_response.data.data
          ) {
            this.mother_transactions = mother_transactions_response.data.data;
          } else {
            this.mother_transactions = [];
          }
          this.loading = false;
        });



        axios
        .get(
          "Cart/total_individual_merchant_commission/" + this.selected_seller + "/"
        )
        .then((response) => {

          this.total_payable=response.data.overall_receiveable;
          this.total_receivableable=response.data.overall_payable;

         });
    },
    getSellerList() {
      // console.log("tese list seller");
      axios.get(`user/show_all_merchants/`).then((response) => {
        this.seller_list = response.data.data;
      });
    },

    // Get all transaction list
  },

  created() {
    this.getSellerList();
    this.getAllTransactions();
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>
